import BasePage from './BasePage';
import useWindowWidth from '../../hooks/useWindowWidth';
import useWindowHeight from '../../hooks/useWindowHeight';
import React, { createContext, useEffect, useState, useRef } from 'react';

export const GradientContext = createContext(null);
export const ContactContext = createContext(null);
export const BackgroundContext = createContext(null);
export const ZoomLevelContext = createContext(null);
export const AnimationsActiveContext = createContext(null);

export const basePageWrap =
  (Component, staticPropOverrides = {}) =>
  (props) => {
    const [hasSetBg, setHasSetBg] = useState(false);
    const [whiteBackground, toggleWhiteBackground] = useState(false);
    const windowWidth = useWindowWidth();
    const windowHeight = useWindowHeight();
    const initialScreenWidth = useRef();
    const [browserZoomPercentage, setBrowserZoomPercentage] = useState(100);

    useEffect(() => {
      if (!initialScreenWidth.current) {
        initialScreenWidth.current = window.outerWidth;
      }

      const userAgent = navigator.userAgent;
      let outerWidth = window.outerWidth;
      if (userAgent.match(/firefox|fxios/i)) {
        outerWidth = initialScreenWidth.current;
      }

      const zoomPercentage = (outerWidth / window.innerWidth) * 100;
      setBrowserZoomPercentage(zoomPercentage);
    }, [windowWidth, windowHeight]);

    const mapper = {
      ['purple-blue']: 'PurpleBlue',
      ['pink-orange']: 'PinkOrange',
      ['pink-blue']: 'PinkBlue',
      ['pink-purple']: 'PinkPurple',
      ['blue-green']: 'BlueGreen',
    };

    const [animationsActive, setAnimationsActive] = useState(true);
    const animationsContext = {
      animationsActive,
      toggleAnimationsActive: () => setAnimationsActive(!animationsActive),
    };

    return (
      <ContactContext.Provider value={{ url: props.contactUrl }}>
        <GradientContext.Provider
          value={mapper[props.gradient] || 'PurpleBlue'}>
          <ZoomLevelContext.Provider value={browserZoomPercentage}>
            <AnimationsActiveContext.Provider value={animationsContext}>
              <BasePage
                {...props}
                {...staticPropOverrides}
                _class={Component.name}
                backgroundWhite={whiteBackground}>
                <BackgroundContext.Provider
                  value={{
                    whiteBackground,
                    toggleWhiteBackground,
                    hasSetBg,
                    setHasSetBg,
                  }}>
                  <Component {...props} />
                </BackgroundContext.Provider>
              </BasePage>
            </AnimationsActiveContext.Provider>
          </ZoomLevelContext.Provider>
        </GradientContext.Provider>
      </ContactContext.Provider>
    );
  };

export default BasePage;
