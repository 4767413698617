import React, { forwardRef } from 'react';

import s from './ButtonSecondaryEllipse.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';

const ButtonSecondaryEllipse = forwardRef(
  (
    {
      asLink = false,
      children,
      active,
      disabled,
      onClick,
      size,
      alt = '',
      icon = 'icon-arrow-right-white',
      modifiers = [],
      ...props
    },
    ref
  ) => {
    const rootClasses = classNames(
      s.Root,
      {
        [s.Active]: !!active,
        [s.Disabled]: !!disabled,
        [s.XSmall]: size === 'x-small',
        [s.Small]: size === 'small',
        [s.Large]: size === 'large',
      },
      modifiers.map((m) => s[m])
    );
    return asLink ? (
      <Link {...props} legacyBehavior={true} ref={ref}>
        <a onClick={onClick} className={rootClasses}>
          {icon && (
            <span className={s.IconWrap}>
              <Image
                width={20}
                height={20}
                src={`/img/${icon}.svg`}
                alt={alt}
                className={s.Icon}
              />
              <i className={s.Bg} aria-hidden={true} />
            </span>
          )}
          {children}
        </a>
      </Link>
    ) : (
      <button
        type="button"
        onClick={onClick}
        className={rootClasses}
        {...props}
        ref={ref}>
        {icon && (
          <span className={s.IconWrap}>
            <Image
              width={20}
              height={20}
              src={`/img/${icon}.svg`}
              alt=""
              className={s.Icon}
            />
            <i className={s.Bg} aria-hidden={true} />
          </span>
        )}
        {children}
      </button>
    );
  }
);

ButtonSecondaryEllipse.displayName = 'ButtonSecondaryEllipse';
ButtonSecondaryEllipse.propTypes = {};
ButtonSecondaryEllipse.defaultProps = {};

export default ButtonSecondaryEllipse;
