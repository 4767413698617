import React from 'react';

import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';
import s from './Breadcrumb.module.scss';

const Breadcrumb = ({ items = [] }) => {
  const { t } = useTranslation();
  return (
    <nav className={`${s.Root} grid`} aria-label="Breadcrumbs">
      <div className={s.Container}>
        <ul className={s.Crumb}>
          {items.length > 1 &&
            items.map((item, index) => {
              if (!item.url) {
                return null;
              }
              return (
                <li
                  key={index}
                  className={classNames(s.ListItem, {
                    [s.First]: index === 0,
                    [s.Last]: index === items.length - 1,
                  })}>
                  <Link className={s.Link} href={item.url}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </nav>
  );
};

Breadcrumb.propTypes = {};

Breadcrumb.defaultProps = {};

export default Breadcrumb;
