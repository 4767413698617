import React, { useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import s from './Footer.module.scss';

import {
  AnimationsActiveContext,
  GradientContext,
} from '../../containers/BasePage';
import CookieConsent from '../CookieConsent';

const Footer = ({
  contact = {
    address: {},
  },
  about = [],
  modifiers = [],
}) => {
  const gradient = useContext(GradientContext) || 'PurpleBlue';
  const rootClasses = classNames(
    s.Root,
    'grid',
    modifiers.map((m) => s[m])
  );

  return (
    <div className={rootClasses}>
      <div className={s.LogoContainer}>
        <i className={classNames(s.Gradient, s[gradient])} />
        <Link href="/" legacyBehavior={true}>
          <Image
            width={228}
            height={54}
            src="/img/logo.svg"
            alt="Stockholm - capital of Scandinavia"
            className={s.Logo}
          />
        </Link>
      </div>
      {!!contact?.address?.name && (
        <div className={s.Contact}>
          <div className={s.Title}>Contact</div>
          <div className={s.Address}>
            <div className={s.Name}>{contact.address.name}</div>
            <div className={s.Street}>{contact.address.street}</div>
            <div className={s.Zip}>
              {contact.address.zip} {contact.address.city}
            </div>
          </div>
          <div className={s.ContactLinks}>
            {!!contact?.phone && (
              <div className={s.Phone}>
                <a href={`tel:${contact.phone.replace(/\s+/g, '')}`}>
                  {contact.phone}
                </a>
              </div>
            )}
            {!!contact?.email && (
              <div className={s.Email}>
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={s.About}>
        <div className={s.Title}>About This Website</div>

        {about.map((item, index) => (
          <div key={index} className={s.AboutItem}>
            {item.href && (
              <Link href={item.href} legacyBehavior={true}>
                {item.text}
              </Link>
            )}
          </div>
        ))}

        <div className={s.AboutItem}>
          <CookieConsent
            buttonClass={s.AboutItem + ' cookie-consent-settings'}
          />
        </div>
      </div>
      {!modifiers.includes('NoBg') && <FooterBackground />}
    </div>
  );
};

const FooterBackground = () => {
  const { animationsActive } = useContext(AnimationsActiveContext) || {};
  const gradient = useContext(GradientContext);

  const classes = classNames(s.Video, s[gradient]);

  const videoRef = useRef(null);
  const desktopVideoRef = useRef(null);

  useEffect(() => {
    if (animationsActive) {
      if (videoRef.current) {
        videoRef.current.play();
      }

      if (desktopVideoRef.current) {
        desktopVideoRef.current.play();
      }
    } else {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0.3;
      }

      if (desktopVideoRef.current) {
        desktopVideoRef.current.pause();
        desktopVideoRef.current.currentTime = 0.3;
      }
    }
  }, [animationsActive]);

  return (
    <div className={classes}>
      <video
        data-matomo-ignore
        className={s.PlattanMobile}
        ref={videoRef}
        preload="auto"
        playsInline
        autoPlay={animationsActive}
        muted
        loop>
        <source
          src="/video/plattan-gradient-mobile.mp4"
          type={`video/mp4; codecs="hvc1"`}
        />
        <source src="/video/plattan-gradient-mobile.webm" type="video/webm" />
        <track kind="subtitles" src="/video/no-audio.vtt" srclang="en" />
      </video>
      <video
        data-matomo-ignore
        className={s.PlattanDesktop}
        ref={desktopVideoRef}
        preload="auto"
        playsInline
        autoPlay={animationsActive}
        muted
        loop>
        <source
          src="/video/plattan-gradient.mp4"
          type={`video/mp4; codecs="hvc1"`}
        />
        <source src="/video/plattan-gradient.webm" type="video/webm" />
        <track kind="subtitles" src="/video/no-audio.vtt" srclang="en" />
      </video>
    </div>
  );
};
Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
