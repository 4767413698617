import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

function useWindowHeight(delay = 120) {
  const [height, setHeight] = useState(null);

  useEffect(() => {
    if (window?.innerHeight) {
      setHeight(window?.innerHeight);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setHeight(window?.innerHeight);
    const debouncedHandleResize =
      delay > 0 ? debounce(handleResize, delay) : handleResize;

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [delay]);

  return height;
}

export default useWindowHeight;
