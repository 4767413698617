import React from 'react';

import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import s from './SkipContent.module.scss';

const SkipContent = () => {
  const { t } = useTranslation();
  return (
    <a href="#main-content" className={s.Root}>
      {t('SkipContent.text')}
    </a>
  );
};

SkipContent.propTypes = {};

SkipContent.defaultProps = {};

export default SkipContent;
