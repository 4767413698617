import React, { useContext } from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import s from './ButtonPrimary.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import { GradientContext } from '../../containers/BasePage';

const ButtonPrimary = ({
  asLink = false,
  hideStroke = false,
  children,
  disabled,
  size,
  icon,
  modifiers = [],
  ...props
}) => {
  const gradient = useContext(GradientContext);

  const rootClasses = classNames(
    s.Root,
    {
      [s.Disabled]: !!disabled,
      [s.Small]: size === 'small',
    },
    s[gradient],
    modifiers.map((m) => s[m])
  );

  return asLink ? (
    <Link {...props} legacyBehavior={true}>
      <a className={rootClasses}>
        {!hideStroke && <i className={s.Bg} aria-hidden={true} />}
        {icon && (
          <Image
            width={20}
            height={20}
            src={`/img/${icon}.svg`}
            alt=""
            className={s.Icon}
          />
        )}
        {children}
      </a>
    </Link>
  ) : (
    <button type="button" className={rootClasses} {...props}>
      {!hideStroke && <i className={s.Bg} aria-hidden={true} />}
      {icon && (
        <Image
          width={20}
          height={20}
          src={`/img/${icon}.svg`}
          alt=""
          className={s.Icon}
        />
      )}
      {children}
    </button>
  );
};

ButtonPrimary.propTypes = {};

ButtonPrimary.defaultProps = {};

export default ButtonPrimary;
