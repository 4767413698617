import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

function useWindowWidth(delay = 120) {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (window?.innerWidth) {
      setWidth(window?.innerWidth);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window?.innerWidth);
    const debouncedHandleResize =
      delay > 0 ? debounce(handleResize, delay) : handleResize;

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [delay]);

  return width;
}

export default useWindowWidth;
