import React from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import s from './ButtonSecondary.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';

const ButtonSecondary = ({
  borderColor = 'white',
  asLink = false,
  children,
  disabled,
  size,
  icon,
  onClick,
  openInNewTab = false,
  modifiers = [],
  ...props
}) => {
  const rootClasses = classNames(
    s.Root,
    {
      [s.BorderWhite]: borderColor === 'white',
      [s.BorderBlack]: borderColor !== 'white',
      [s.Disabled]: !!disabled,
      [s.Small]: size === 'small',
    },
    modifiers.map((m) => s[m])
  );
  return asLink ? (
    <Link {...props} legacyBehavior={true}>
      <a
        onClick={onClick}
        target={openInNewTab && '_blank'}
        className={rootClasses}>
        <i className={s.Bg} aria-hidden={true} />
        {icon && (
          <Image
            width={20}
            height={20}
            src={`/img/${icon}.svg`}
            alt=""
            className={s.Icon}
          />
        )}
        {children}
      </a>
    </Link>
  ) : (
    <button type="button" className={rootClasses} onClick={onClick} {...props}>
      <i className={s.Bg} aria-hidden={true} />
      {icon && (
        <Image
          width={20}
          height={20}
          src={`/img/${icon}.svg`}
          alt=""
          className={s.Icon}
        />
      )}
      {children}
    </button>
  );
};

ButtonSecondary.propTypes = {};

ButtonSecondary.defaultProps = {};

export default ButtonSecondary;
