import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './ButtonBurger.module.scss';

const ButtonBurger = ({ isOpen, forceOpen }) => {
  const burgerClasses = classNames(s.Burger, {
    [s.Open]: isOpen || forceOpen,
    [s.Closed]: isOpen !== null && !isOpen,
    [s.Force]: forceOpen,
  });

  return (
    <svg
      fill="var(--color-white)"
      className={burgerClasses}
      viewBox="0 0 100 100"
      width="26">
      <rect
        className={s.BurgerLineTop}
        width="30"
        height="8"
        x="70"
        y="10"></rect>
      <rect
        className={s.BurgerLineMiddle}
        width="60"
        height="8"
        x="40"
        y="45"></rect>
      <rect
        className={s.BurgerLineBottom}
        width="90"
        height="8"
        x="10"
        y="80"></rect>
    </svg>
  );
};

ButtonBurger.propTypes = {
  isOpen: PropTypes.bool,
};

ButtonBurger.defaultProps = { isOpen: false };

export default ButtonBurger;
